import React from "react";
// import { Route, Switch } from "react-router-dom";
import Board from "./board";
// import Developer from "./Developer";
import "./styles.css";

function App() {
  return (
    <div className="App">
      <Board />
      {/* <Developer /> */}
    </div>
  );
}

export default App;
